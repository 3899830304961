@import "libs/font.css";
@import "libs/minireset.css";
@import "libs/responsive.scss";

/*Variables : */
$primary_color : #EE8114;
$primary_color--light : #F4BC84;

$secondary_color : #61388B;

$text : #404545;

$grey-light : #6B6F6F;


*, ::after, ::before{
    box-sizing: border-box;
}

html{
    font-size: 18px;
    scroll-behavior: smooth;
}

body{
    background-color: white;
    color: $text;
    font-family: 'Noto Serif JP', serif;
    font-weight: 400;
    margin: 0;
}

h2{
    font-size: 25px;
    color: $primary_color;
    text-align: center;
    margin-bottom: 50px;
        @media #{$large-up}{
            font-size: 45px;
            margin-bottom: 80px;
        }
}

/*Petit trait décoratif au dessus des titres de section*/
.h2-deco::before{
    content: "";
    display: block;
    width: 1.2em;
    height: 0.1em;
    background-color: $primary_color;
    margin: 0 auto 20px;
        @media #{$large-up}{
            height: 0.067em;
            margin-bottom: 30px;
        }
}

h3{
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 22px;
    color : $secondary_color;
    text-align: center;
    margin-bottom: 50px;
        @media #{$medium-up}{
            margin-bottom: 80px;
        }
        @media #{$large-up}{
            font-size: 35px;
        }
}

h4{
    font-size: 23px;
    color: $primary_color;
    text-align: center;
    margin: 30px 0;
        @media #{$xlarge-up}{
            margin-bottom: 35px;
        }
}

h5{
    font-size: 20px;
    color: $primary_color;
    text-align: left;
    margin-bottom: 30px;
        @media #{$medium-up}{
            text-align: center;
            margin-bottom: 35px;
        }
}

/*classe utilitaire pour styliser "Cleriam" aux couleurs, à la police et au graissage du logo*/
.cleriam-style{
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    color: $secondary_color;

        &__span{
            color: $primary_color;
            font-weight: 400;
        }
}

a{
    text-decoration: none;
    color: $primary_color;
    font-size: 18px;
    transition: color .3s;
        @media #{$large-up}{
            font-size: 20px;
        }
}

a:hover{
    color: $primary_color--light;
}

img{
    width: 100%;
    height: 100%;
}

p{
    line-height: 1.33rem;
}

.btn{
    color: $primary_color;
    font-family: 'Raleway', serif;
    font-size: 16px;
    font-weight: bold;
    background-color: white;
    border : 2px solid $primary_color;
    padding: 15px 30px;
    transition: all .3s;
        @media #{$medium-up}{
            padding-left: 60px;
            padding-right: 60px;
        }

        &:hover,
        &:focus-visible{
            border-color: $primary_color--light;
            color : $primary_color--light;
            outline: none;
        }
}

/*classe utilitaire pour styler le cercle avec chiffre (section patrimoine et retraite)*/
.circle-number{
    counter-increment: circle 1; /*augmente le chiffre dans chaque cercle de 1*/

        &::before{
            content: counter(circle);
            display: block;
            text-align: center;
            line-height: 1.35em; /*centre verticalement le chiffre dans le cercle*/
            border: solid 3px $primary_color--light;
            border-radius: 50%;
            width: 2.5rem;
            height: 2.5rem;
            margin : 0 auto 20px;
            color: $primary_color--light;
            font-size: 1.44em;
            font-weight: 500;
        }
}

/*classe utilitaire pour image de puce des li */
.li-style{
    display: flex;
    align-items: center;
    gap : 20px;

        &::before{
            content: "";
            display: inline-block;
            width: 1.11em;
            height: 1.11em;
            background-image: url("../img/icons/check.svg"); /*url à changer selon image à afficher en guise de puce*/
            background-repeat: no-repeat;
            background-size: cover;
            flex: none;
        }
}

main section{
    padding: 40px 20px;
        @media #{$large-up}{
            padding-top : 80px;
            padding-bottom: 80px;
        }
}


/*header contient header__bands + intro*/
header{
    padding-bottom: 10px;
        @media #{$medium-up}{
            padding-bottom: 40px;
        }
}

.header__bands{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    transition: transform 0.5s;
}

.header__contact{
    background-color: #f2a04f; /* équivaut à $primary_color avec transparence de 75% */
    color: white;

    &__phone{
        display: flex;
        align-items: center;
        column-gap: 5px;

            img{
                width: 20px;
                height: 20px;
                color: white;
            }

            a{
                color: white;
            }
    }
}

/*bandeau contact pour écran mobiles*/
.header__contact--mobile{
    font-size: 16px;
    padding: 15px 35px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;

        @media #{$medium-up}{
            display: none;
            height: 0;
        }

        &__phone{
            align-items: center;
            column-gap: 5px;

                img{
                    width: 20px;
                    height: 20px;
                }
        }
}

/*header__nav correspond au bandeau blanc de navigation*/
.header__nav{
    position: relative;
    background-color: white;
    height: 80px;
    border-bottom: 1px solid rgba($grey-light, .2);
        @media #{$medium-up}{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 105px;
        }
}

.logo__img{
    display: block;
    width: 80px;
    height: 80px;
    margin : 0 auto;
        @media #{$medium-up}{
            width: 105px;
            height : 105px;
            margin: 0 0 0 40px;
        }
        @media #{$large-up}{
            margin-left: 60px;
        }
}

.header__nav__links{
    display: flex;
    column-gap: 2em;
    margin-right: 40px;
        @media #{$large-up}{
            margin-right: 60px;
        }

}

/*Liens de navigation, n'apparaissent qu'à partir des ecrans format tablette*/
.navbar{
    display: none;
        @media #{$medium-up}{
            display: flex;
            align-items: center;
            gap : 2em;
        }
}

.dropdown{
    position: relative;

        a{
            display: block;
        }

        /*le lien "services" à cliquer pour ouvrir le sous-menu*/
        &__link{
            position: relative;
            z-index : 1;
            margin-right: calc(1.11em + 0.45em); /*Prise en compte de width + margin du ::after (chevron vers le bas apres services) pour avoir 3em entre les liens */

            /*Chevron vers le bas SVG à coté de "services"*/
            &::after{
                content: "";
                display: inline-block;
                width: 1.11em;
                height: 1.11em;
                background: url('../img/icons/chevron-down.svg') center center no-repeat;
                position: absolute;
                top: 0.33em;
                margin-left: 0.45em;
                transition: all .3s;
            }

            &:hover::after{
                opacity: .4;
                transform: translate(0, 2px);
            }
        }

        /*le sous menu de services (patrimoine, retraite, autonomie et décès*/
        &__list{
            @media #{$medium-up}{
                display: none;
                text-align: center;
                background-color: white;
                position: absolute;
                padding: 20px 10px 0;
                top : 65px;
                left : -50px;
                right: -50px;
                border-top: 1px solid $primary_color;
                box-shadow: 20px 20px 20px -22px rgba(0, 0, 0, 0.15);
            }
            @media #{$large-up}{
                left: -60px;
                right: -60px;
                top : 66.5px;
            }

            li{
                margin-bottom: 30px;
            }
        }
}

/* numéro de contact dans le bandeau version tablette et desktop*/
.header__contact--desktop{
    display: none;
    padding: 10px 15px;
    border-radius: 15px;
        @media #{$medium-up}{
            display: block;
        }

        .header__contact__phone{
            align-items: end;
            column-gap: 10px;
        }
    
        img{
            width: 25px;
            height: 25px;
        }
}

/*icon burger menu*/
.mobile-menu__icon{
    width: 30px;
    height: 30px;
    position: absolute;
    top : 25px;
    right: 35px;
        @media #{$medium-up}{
            display: none;
        }

        &:hover{
            opacity: .5;
        }
}

.mobile-menu__modal{
    display: none;
    position: fixed;
    z-index : 2;
    top : 0;
    left: 0;
    background-color:rgb(#FFFFFF, 20%);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    width: 100%;
    height: 100%;
    overflow: auto;
}

.modal{
    &__content{
        background-color: white;
        border: solid 1px $primary_color;
        position: fixed;
        left : 30px;
        right: 30px;
        top : 50px;
        padding: 10px 0 5px;
        max-width: 400px;
        margin : 0 auto;

        .logo__img{
            margin: 0 auto 40px;
            width: 100px;
            height: 100px;
        }
    }

    &__close-icon{
        width: 25px;
        height: 25px;
        margin-left: auto;
        position: absolute;
        top: 40px;
        right: 30px;
        transition: all .3s;

        &:hover{
            opacity: .5;
            transform: scale(1.1);
        }
    }

    &__nav{
        text-align: center;
            &__item{
                margin-bottom: 35px;
            }
    }
}

/*image + titre H1 et texte d'accroche*/
.intro{
    padding-top: calc(54px + 80px); /*pour prendre en compte la hauteur de --mobile et header__nav qui sont en position fixed*/
        @media #{$medium-up}{
            padding-top: 105px; /*header__contact--mobile display none, header__nav 105px */
            display: flex;
            position: relative; /*pour que le box shadow de nav se voit par dessus l'image*/
            z-index: 1;
        }
    &__img{
        max-width: 100%;
            @media #{$medium-up}{
                max-width: 60%;
            }
    }

    &__content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    h1{
        font-size: 21px;
        font-weight: bold;
        color: $secondary_color;
        line-height: 1.66em;
        text-align: center;
        max-width: 290px;
        margin-top: 40px;
            @media only screen and (min-width : 550px) {
                font-size: 25px;
                max-width: 345px;
            }
            @media #{$medium-up}{
                font-size: 20px;
                max-width: 290px;
                margin-top: 0;
            }
            @media #{$large-up}{
                font-size: 25px;
                max-width: 345px;
            }
            @media #{$xlarge-up}{
                font-size: 30px;
                max-width: 410px;
            }

            span{
                font-weight: 400;
                color: $primary_color;
                display: inline-block;
            }
    }
}


//section offre
.section__offre__content{
    display: flex;
    flex-direction: column;
    gap : 50px;
    align-items: center;
        @media #{$medium-up} {
            display: grid;
            max-width: 690px;
            grid-template: repeat(3, 1fr) / repeat(2, 1fr);
            gap : 50px 80px;
            align-items: start;
            justify-items: center;
            margin: 0 auto;
        }
        @media #{$xlarge-up}{
            grid-template: 1fr auto / repeat(4, 1fr);
            max-width: 1180px;
            gap : 30px 80px;
        }
}

/*icon SVG + texte*/
.offre__item{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 303px;
        @media #{$xlarge-up}{
            max-width: 240px;
        }

    &__icon{
        height: 60px;
        width: 60px;
    }

    p{
        text-align: center;
    }

    &:last-child{
        gap : 15px;
        max-width: 330px;
            @media #{$medium-up}{
                grid-column: 1 / 3;
                justify-self: center;
            }
            @media #{$xlarge-up}{
                grid-column: 1 / 5;
                max-width: 460px;
            }

            span{
                display: block;
                color: $primary_color;
            }
    }
}

//section patrimoine
.section__patrimoine__content{
    @media #{$large-up}{
        display: flex;
        justify-content: center;
        align-items: center;
        gap : 80px;
        max-width: 1060px;
        margin: 0 auto;
    }
    @media #{$xlarge-up}{
        gap : 150px;
        padding-top: 30px;
    }
}

.patrimoine{
    &__img{
        max-width: 490px;
        max-height: 326px;
        margin: 0 auto 40px;
        position: relative;
            @media #{$large-up}{
                margin : 0;
            }

        /*bordure décalée visible sur écran format desktop*/
        &::before{
            @media #{$xlarge-up}{
                content: "";
                display: block;
                border: solid 1px $primary_color;
                width: 100%;
                height: 100%;
                position: absolute;
                top : -30px;
                left: -30px;
                z-index: -1;
            }
        }
    }


    &__items{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
            @media #{$large-up}{
                align-items: start;
            }

            p{
                text-align: center;
                max-width: 330px;
                    @media #{$large-up}{
                        text-align: left;
                        max-width: 400px;
                    }
            }

            .circle-number{
                @media #{$large-up}{
                    display: flex;
                    gap: 30px;
                    align-items: center;
                }

                &::before{
                    @media #{$large-up}{
                        flex: none;
                        margin: 0;
                    }
                }
            }
    }
}

//section retraite
.section__retraite__content{
    @media #{$xlarge-up}{
        display : grid;
        grid-template: 1fr 1fr / 1fr 1fr;
        justify-items: center;
        max-width: 1100px;
        margin: 0 auto;
    }

    .circle-number{
        @media #{$xlarge-up}{
            display: flex;
            gap: 30px;
            align-items: center;
        }

        &::before{
            @media #{$xlarge-up}{
                flex: none;
                margin: 0;
            }
        }
    }
}

.retraite{
    &__img{
        max-width: 550px;
        margin: 0 auto;

        &--second{
            display: none;
            @media #{$xlarge-up}{
                display: block;
                grid-column: 2 / 3;
            }
        }
    }

    &__list{
        &--first{
            @media #{$xlarge-up}{
                margin-left: 25px;
            }

            h4{
                @media #{$large-up}{
                    margin-top: 0;
                }
            }

            /*petite barre deco de séparation entre les 2 retraite__list*/
            &::after{
                content: "";
                display: block;
                width: 100px;
                height: 1px;
                background-color: $primary_color;
                margin: 0 auto 30px;
                    @media #{$medium-up}{
                        width: 150px;
                        margin : 60px auto 55px;
                    }
                    @media #{$xlarge-up}{
                        display: none;
                    }
            }
        }

        &--second{
            @media #{$xlarge-up}{
                grid-column: 1 / 2;
                grid-row: 2 / 3;
            }

            h4{
                @media #{$xlarge-up}{
                    margin-top: 50px;
                }
            }

            p:last-child{
                margin-bottom: 0;
            }
        }

        &__items{
            max-width: 360px;
            margin: 0 auto;
                @media #{$medium-up}{
                    max-width: 455px;
                }

            p{
                text-align: center;
                margin-bottom: 40px;
                    @media #{$xlarge-up}{
                        text-align: left;
                    }
            }
        }
    }
}

//section autonomie
.section__autonomie{
    padding-left: 50px;
    padding-right: 50px;
        @media #{$xlarge-up}{
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom : 120px;
        }

        h2{
            margin-bottom: 20px;
        }

        &__content{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap : 50px 30px;
            margin: 0 auto;
            min-width: 233px;
            max-width: 884px;
                @media #{$medium-up}{
                    gap: 80px 30px;
                }
                @media #{$xlarge-up}{
                    max-width: 1300px;
                    gap : 0 50px;
                }
        }
}

.autonomie{
    &__img{
        display: none;
            @media #{$medium-up}{
                display: block;
                width: 110px;
                height: 110px;
                margin: 0 auto 35px;
            }
    }

    &__item{
        width: 275px;
            @media #{$xlarge-up}{
                width: 21%; /*a 20% le h5 du 3ème item ne reste pas sur une ligne pour écran à 1200px*/
            }

        /*Pour décaler et centrer un peu le texte du 3ème item car peu de texte par rapport aux autres items*/
        &:nth-child(3) ul{
            @media #{$medium-up}{
                margin-left: 25px;
            }
            @media #{$xlarge-up}{
                margin-left: 30px;
            }
        }

        li{
            margin-bottom: 20px;
                @media #{$medium-up}{
                    margin-bottom: 25px;
                }

            &:last-child{
                margin-bottom: 0;
            }
        }

        .li-style{
            @media #{$xlarge-up}{
                gap : 15px;
            }
        }
    }
}


//section décès
.section__deces{
    @media #{$large-up}{
        background-image: linear-gradient(rgb(255, 255, 255, 0.45), rgb(255, 255, 255, 0.45)), url('../img/section-deces-bg.jpg?as=webp');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding-top: 120px;
        padding-bottom: 120px;
    }

    h2{
        @media #{$large-up}{
            width: 840px;
        }
    }

    &__content{
        @media #{$large-up}{
            /*pour centrer le ul sous le h2*/
                display: flex;
                flex-direction: column;
                width : 840px; /* = width du h2*/
                margin-left: 20px;
            }
    }

}

.deces__list{
    border: solid 1px $primary_color;
    padding: 20px;
    max-width: 445px;
    margin: 0 auto;
    font-size: 16px;
        @media #{$medium-up}{
            padding: 30px 15px 30px 30px;
        }
        @media #{$large-up}{
            background-color: white;
            border : solid 4px $primary_color--light;
            max-width: 450px;
            margin: 0;
            padding: 40px 20px 40px 40px;
            align-self: center;
        }

    .li-style::before{
        background-image: url('../img/icons/star.svg');
    }

    li{
        margin-bottom: 20px;
            @media #{$large-up}{
                margin-bottom: 25px;
            }

        &:last-child{
            margin-bottom: 0;
        }
    }
}

//section app
.section__app__content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap : 50px;
        @media #{$large-up}{
            flex-direction: row;
            justify-content: center;
            gap : 80px;
        }
        @media #{$xlarge-up}{
            gap : 120px;
        }
}

.app__img{
    width: 120px;
    height: 120px;
    flex : none;
        @media #{$medium-up}{
            width: 160px;
            height: 160px;
        }
        @media #{$large-up}{
            width: 200px;
            height: 200px;
        }
}

.app__item{
    &--first{
        max-width: 280px;
        text-align: center;
    }

    &--second{
        max-width: 300px;
    }

    ul{
        margin-left: 20px;

        li:first-child{
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}

//section contact
.section__contact{
    background-color: rgba($primary_color--light, .60);
}

.form__contact{
    display: flex; /*Aligner formulaire et btn submit */
    flex-direction: column;

        label{
            color: $primary_color;
        }

        input, 
        textarea{
            font-size: 18px;
            font-family: Arial, Helvetica, sans-serif;
            border: 1px solid $primary_color;
            transition: all .3s;

            &:focus-visible{
                outline: $primary_color solid 2px;
            }
        }

        input{
            height: 45px;
        }

        textarea{
            height: 100px;
        }

        .btn{
            flex-grow: 0;
            width: min-content;
            margin-top: 50px;
            margin-left: auto;
            margin-right: auto;
                @media #{$large-up}{
                    width: 235px;
                }
        }
}

.form__contact__items{
    display: flex; /*Aligner chaque groupe label/input*/
    flex-direction: column;
    row-gap: 30px;
    width: 260px;
    margin: 0 auto;
    align-items: center;
        @media #{$small-up} {
            width: 400px;
        }
        @media #{$large-up} {
            display: grid; /*Sur écran large input nom et prénom côte à côte */
            grid-template: repeat(4, min-content) / 1fr 1fr;
            justify-content: center;
            width: 700px;
        }

        input,
        textarea{
            width: 260px;
                @media #{$small-up} {
                    width: 400px;
                }
                @media #{$large-up} {
                    width: 700px;
                }
        }

        .form__row{
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            position: relative;
                @media #{$large-up} {
                    grid-column : 1 / 3;
                }
                &--nom{
                    @media #{$large-up} {
                        grid-column : 1 / 2;
                    }

                    input{
                        @media #{$large-up} {
                            width: 330px; /* largeur form__contact__items - 40px (espace entre les 2 input) / 2 */
                        }
                    }
                }
                &--prenom{
                    @media #{$large-up} {
                        grid-column : 2 / 3;
                        justify-self: flex-end;
                    }

                    input{
                        @media #{$large-up} {
                            width: 330px; /* largeur form__contact__items - 40px (espace entre les 2 input) / 2 */
                        }
                    }
                }
        }
}

.form__row .not-valid{
    border : red 2px solid;
}

.form__contact__items.form-not-valid{
    .form__row{
        margin-bottom: 10px; /*Espacer les messages d'erreur et éviter décalage entre les input en erreur et les valides*/
    }
    .form__row:has(textarea){
        margin-bottom: 0;
        margin-top: 10px;
    }
}

.error__message{
    display: none;
    color: red;
    font-size: 16px;
    position: absolute;
    bottom: -53px;
    height: 50px;
}


footer{
    width : 100%;
    background: rgb(#6B6F6F, 5%);
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    text-align: center;
    padding: 10px 0 35px;
        @media #{$medium-up}{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 60px;
        }

    .logo__img{
        @media #{$medium-up}{
            margin: 0;
        }
    }
}

.social-links{
    color : rgb($text, 80%);
    margin: 35px 0 40px;
        @media #{$medium-up}{
            display: flex;
            align-items: center;
            gap : 10px;
            margin: 0;
        }

    &__list{
        margin-top: 10px;
        display: flex;
        justify-content: center;
        gap : 10px;
            @media #{$medium-up}{
                margin: 0;
            }
    }

    a{
        display: block;
        width: 25px;
        height : 25px;
    }
}

.legal-mentions{
    color : rgb($text, 80%);
    font-size: 16px;

    &:hover{
        color : rgb($text, 50%);
    }
}

/*CSS with JS*/
.show {
    display: block;
}

/* fait apparaitre et disparaitre le bandeau header au scroll */
.header__bands.scroll-down{
    transform: translateY(-100%);
}

.header__bands.scroll-up{
    transform: none;
}

#modal.show .modal__content{
    animation: animationIn .3s;
}

#modal[aria-hidden="true"]{
    animation: animationOut .4s;
}

@keyframes animationIn {
    from {opacity : 0;}
    to {opacity : 1;}
}

@keyframes animationOut {
    from {opacity : 1}
    to {opacity : 0}
}